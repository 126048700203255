import { ThemeProvider } from "@mui/material/styles";
import LoginScreen from "./screens/LoginScreen";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { setupInterceptors } from "./Settings/Interceptor";
import axios from "axios";
import RegisterScreen from "./screens/RegisterScreen";
import ActivationScreen from "./screens/ActivationScreen";
import DashboardScreen from "./screens/DashboardScreen/DashboardScreen";
import { Box, Typography } from "@mui/material";
import SideNavigation from "./components/SideNavigation";
import { globalTheme } from "./theme/GlobalTheme";
import CandidateApplicationScreen from "./screens/CandidateApplicationScreen";
import { useAppSelector } from "./store/store";
import ProtectedRoute from "./router/ProtectedRoute";
import ErrorScreen from "./screens/ErrorScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import DocumentsScreen from "./screens/DocumentsScreen";
import VouchersScreen from "./screens/VouchersScreen/VouchersScreen";
import ApplicationsScreen from "./screens/ApplicationsScreen";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CandidateGuarantorScreen from "./screens/CandidateGuarantorScreen/CandidateGuarantorScreen";
import ResourcesScreen from "./screens/ResourcesScreen";
import TripScreen from "./screens/TripScreen/TripScreen";
import ConsularAppointmentScreen from "./screens/ConsularAppointmentScreen";
import QRCodeScreen from "./screens/QRCodeScreen";
import JobBoardScreen from "./screens/JobBoardScreen";
import PreDepartureScreen from "./screens/PreDepartureScreen";
import DS160Screen from "./screens/DS160Screen";
import AccountBalanceScreen from "./screens/AccountBalanceScreen";
import ProfileScreen from "./screens/ProfileScreen";
import PaymentScreen from "./screens/PaymentScreen/PaymentScreen";
import EventTicketScreen from "./screens/EventTicketScreen";
import ReferidoScreen from "./screens/ReferidoScreen";

setupInterceptors(axios);
const drawerWidth = 240;

function App() {
  const isSignedIn = useAppSelector((state) => state.appState.isSignedIn);
  var appState = useAppSelector((state) => state.appState);
  const { pathname } = useLocation();

  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={globalTheme}>
          <Box sx={{ display: "flex" }}>
            {isSignedIn && !appState.isAdmin ? <SideNavigation /> : null}

            <Box
              component="main"
              className="main"
              sx={{
                flexGrow: 1,
                p: 3,
                width: { xs: `calc(100% - ${drawerWidth}px)` },
              }}
            >
              {isSignedIn ? (
                <Box
                  sx={{
                    display: { xs: "block", lg: "none" },
                    height: { xs: 50, lg: 0 },
                  }}
                ></Box>
              ) : null}

              <Routes>
                <Route element={<ProtectedRoute />}>
                  <Route path="/" element={<DashboardScreen />} />
                </Route>

                <Route element={<ProtectedRoute />}>
                  <Route
                    path="/candidateapplication"
                    element={
                      (pathname == "/candidateapplication" &&
                        appState.editForm) ||
                      appState.isAdmin ? (
                        <CandidateApplicationScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route
                    path="/documents"
                    element={
                      pathname == "/documents" &&
                      appState.isValidForDocument &&
                      ((appState.welcomeCallNeeded &&
                        appState.welcomeCallDateTime != null) ||
                        !appState.welcomeCallNeeded ||
                        appState.welcomeCallCompleted) ? (
                        <DocumentsScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route
                    path="/vouchers"
                    element={
                      pathname == "/vouchers" && appState.haveToPay ? (
                        <VouchersScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route
                    path="/applications"
                    element={<ApplicationsScreen />}
                  />
                  <Route
                    path="/garantes"
                    element={
                      pathname == "/garantes" &&
                      appState.viewGuarantor &&
                      ((appState.welcomeCallNeeded &&
                        appState.welcomeCallDateTime != null) ||
                        !appState.welcomeCallNeeded ||
                        appState.welcomeCallCompleted) ? (
                        <CandidateGuarantorScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route path="/recursos" element={<ResourcesScreen />} />
                  <Route
                    path="/trip"
                    element={
                      pathname == "/trip" &&
                      appState.tripDetailCandidateID != null &&
                      appState.tripDetailCandidateID > 0 ? ( //&& appState.tripDetail
                        <TripScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />

                  <Route
                    path="/consularappointment"
                    element={
                      pathname == "/consularappointment" &&
                      appState.isValidForDS160 &&
                      ((appState.welcomeCallNeeded &&
                        appState.welcomeCallDateTime != null) ||
                        !appState.welcomeCallNeeded ||
                        appState.welcomeCallCompleted) ? (
                        <ConsularAppointmentScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />

                  <Route
                    path="/qrcode"
                    element={
                      pathname == "/qrcode" &&
                      appState.isQRValid &&
                      ((appState.welcomeCallNeeded &&
                        appState.welcomeCallDateTime != null) ||
                        !appState.welcomeCallNeeded ||
                        appState.welcomeCallCompleted) ? (
                        <QRCodeScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />

                  <Route
                    path="/jobboard"
                    element={
                      pathname == "/jobboard" &&
                      appState.jobBoard &&
                      ((appState.welcomeCallNeeded &&
                        appState.welcomeCallDateTime != null) ||
                        !appState.welcomeCallNeeded ||
                        appState.welcomeCallCompleted) ? (
                        <JobBoardScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />

                  <Route
                    path="/predeparture"
                    element={
                      pathname == "/predeparture" &&
                      appState.isQRValid &&
                      ((appState.welcomeCallNeeded &&
                        appState.welcomeCallDateTime != null) ||
                        !appState.welcomeCallNeeded ||
                        appState.welcomeCallCompleted) ? (
                        <PreDepartureScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />

                  <Route
                    path="/ds160"
                    element={
                      pathname == "/ds160" &&
                      appState.isValidForDS160 &&
                      ((appState.welcomeCallNeeded &&
                        appState.welcomeCallDateTime != null) ||
                        !appState.welcomeCallNeeded ||
                        appState.welcomeCallCompleted) ? (
                        <DS160Screen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />

                  <Route
                    path="/accountbalance"
                    element={
                      pathname == "/accountbalance" ? (
                        <AccountBalanceScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      pathname == "/profile" ? (
                        <ProfileScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />

                  <Route
                    path="/payments"
                    element={
                      pathname == "/payments" && appState.haveToPay ? (
                        <PaymentScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />

                  <Route
                    path="/events"
                    element={
                      pathname == "/events" ? (
                        <EventTicketScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route
                    path="/referidos"
                    element={
                      pathname == "/referidos" ? (
                        <ReferidoScreen />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                </Route>

                <Route path="/login" element={<LoginScreen />} />
                <Route path="/register" element={<RegisterScreen />} />
                <Route path="/activationCode" element={<ActivationScreen />} />
                <Route
                  path="/forgotpassword"
                  element={<ForgotPasswordScreen />}
                />
                <Route
                  path="/*"
                  element={
                    <ErrorScreen
                      title={
                        <Typography
                          color="white"
                          textAlign="center"
                          variant="h4"
                        >
                          La pagina que buscas no esta disponible o ocurrio un
                          error. Por favor intente mas tarde.
                        </Typography>
                      }
                    />
                  }
                />
              </Routes>
            </Box>
          </Box>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;
