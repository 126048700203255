import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ErrorIcon from "@mui/icons-material/Error";
import ReplayIcon from "@mui/icons-material/Replay";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import logo from "../assets/images/ace_logo_big.png";
import { ApplicationState } from "../models/ApplicationState";
import { useAppDispatch, useAppSelector } from "../store/store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { logout } from "../store/appStateReducer";
import { State, setBodyColor } from "../Utils";
import PeopleIcon from "@mui/icons-material/People";
import AttachmentIcon from "@mui/icons-material/Attachment";
import FlightIcon from "@mui/icons-material/Flight";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import QrCodeIcon from "@mui/icons-material/QrCode";
import WorkIcon from "@mui/icons-material/Work";
import LuggageIcon from "@mui/icons-material/Luggage";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ConfirmationDialog from "./ConfirmationDialog";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { Groups } from "@mui/icons-material";

const drawerWidth = 240;

interface NavigationItem {
  name: string;
  path: string;
  icon: React.ReactElement;
  show: boolean;
}

function SideNavigation() {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  var dispatch = useAppDispatch();
  const appState: ApplicationState = useAppSelector((state) => state.appState);

  var [openDialog, setOpenDialog] = useState(false);
  var [dialogTitle, setDialogTitle] = useState("");
  var [dialogDesc, setDialogDesc] = useState("");
  var [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();

  const navigationItems: NavigationItem[] = [
    {
      name: "Inicio",
      path: "/",
      icon: <HomeIcon />,
      show: !appState.mustChangePassword,
    },

    {
      name: "Completar Registro",
      path: "/candidateapplication",
      icon: <ErrorIcon />,
      show: !appState.mustChangePassword && appState.editForm,
    },

    {
      name: "Solicitudes",
      path: "/applications",
      icon: <ReplayIcon />,
      show: !appState.mustChangePassword,
    },
    {
      name: "Documentos",
      path: "/documents",
      icon: <DescriptionIcon />,
      show:
        !appState.mustChangePassword &&
        appState.isValidForDocument &&
        ((appState.welcomeCallNeeded && appState.welcomeCallDateTime != null) ||
          !appState.welcomeCallNeeded ||
          appState.welcomeCallCompleted),
    },
    {
      name: "Vouchers",
      path: "/vouchers",
      icon: <LocalOfferIcon />,
      show: !appState.mustChangePassword && appState.haveToPay,
    },
    {
      name: "Viaje",
      path: "/trip",
      icon: <FlightIcon />,
      show:
        !appState.mustChangePassword &&
        appState.tripDetailCandidateID != null &&
        appState.tripDetailCandidateID > 0,
    },
    {
      name: "Generar QR",
      path: "/qrcode",
      icon: <QrCodeIcon />,
      show:
        !appState.mustChangePassword &&
        appState.isQRValid &&
        ((appState.welcomeCallNeeded && appState.welcomeCallDateTime != null) ||
          !appState.welcomeCallNeeded ||
          appState.welcomeCallCompleted),
    },
    {
      name: "Pre Departure",
      path: "/predeparture",
      icon: <LuggageIcon />,
      show:
        !appState.mustChangePassword &&
        appState.isQRValid &&
        ((appState.welcomeCallNeeded && appState.welcomeCallDateTime != null) ||
          !appState.welcomeCallNeeded ||
          appState.welcomeCallCompleted),
    },
    {
      name: "DS 160",
      path: "/ds160",
      icon: <ArticleIcon />,
      show:
        !appState.mustChangePassword &&
        appState.isValidForDS160 &&
        ((appState.welcomeCallNeeded && appState.welcomeCallDateTime != null) ||
          !appState.welcomeCallNeeded ||
          appState.welcomeCallCompleted),
    },
    {
      name: "Garantes",
      path: "/garantes",
      icon: <PeopleIcon />,
      show:
        !appState.mustChangePassword &&
        appState.viewGuarantor &&
        ((appState.welcomeCallNeeded && appState.welcomeCallDateTime != null) ||
          !appState.welcomeCallNeeded ||
          appState.welcomeCallCompleted),
    },

    {
      name: "Job Board",
      path: "/jobboard",
      icon: <WorkIcon />,
      show:
        !appState.mustChangePassword &&
        appState.jobBoard &&
        ((appState.welcomeCallNeeded && appState.welcomeCallDateTime != null) ||
          !appState.welcomeCallNeeded ||
          appState.welcomeCallCompleted),
    },

    {
      name: "Citas Consulares",
      path: "/consularappointment",
      icon: <CalendarTodayIcon />,
      show:
        !appState.mustChangePassword &&
        appState.isValidForDS160 &&
        ((appState.welcomeCallNeeded && appState.welcomeCallDateTime != null) ||
          !appState.welcomeCallNeeded ||
          appState.welcomeCallCompleted),
    },
    {
      name: "Pagos TC",
      path: "/payments",
      icon: <CreditCardIcon />,
      show: !appState.mustChangePassword && appState.haveToPay,
    },
    {
      name: "Estado de Cuenta",
      path: "/accountbalance",
      icon: <AccountBalanceWalletIcon />,
      show:
        !appState.mustChangePassword &&
        appState.applicationStateId != State.PendienteConfirmacion,
    },
    {
      name: "Perfil",
      path: "/profile",
      icon: <PersonIcon />,
      show: !appState.mustChangePassword,
    },
    {
      name: "Recursos",
      path: "/recursos",
      icon: <AttachmentIcon />,
      show: true,
    },
    {
      name: "Eventos",
      path: "/events",
      icon: <ConfirmationNumberIcon />,
      show: true,
    },
    {
      name: "Referidos",
      path: "/referidos",
      icon: <Groups />,
      show: appState.visado,
    },
  ];

  const { pathname } = useLocation();
  const drawerList = (
    <List>
      {navigationItems
        .filter((el) => el.show)
        .map((el, index) => (
          <ListItem disablePadding key={index}>
            <ListItemButton
              sx={{ bgcolor: "secondary" }}
              selected={pathname == el.path}
              onClick={() => {
                navigate(el.path);
              }}
            >
              <ListItemIcon sx={{ color: "white", marginRight: 1 }}>
                {el.icon}
              </ListItemIcon>
              <ListItemText primary={el.name} sx={{ color: "white" }} />
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Box
      component="nav"
      sx={{ width: { lg: drawerWidth }, flexShrink: { sm: 0 } }}
    >
      <AppBar
        position="fixed"
        sx={{
          display: { lg: "none" },
          //width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ maxHeight: "50px !important" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            //edge="center"
            onClick={handleDrawerToggle}
            sx={{ display: { lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ width: "100%" }}>
            <img
              alt="Logo"
              src={logo}
              style={{ maxHeight: "40px", width: "100%", objectFit: "contain" }}
            />
          </Box>

          {/* <Typography variant="h6" noWrap component="div"></Typography> */}
        </Toolbar>
      </AppBar>

      {/* Mobile Drawe */}

      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "#00166B !important",
          },
        }}
      >
        <Box sx={{ textAlign: "right" }}>
          <IconButton
            sx={{
              color: "white",

              display: "inline-block",
            }}
            onClick={() => {
              setMobileOpen(false);
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>

        <ListItem
          sx={{ justifyContent: "center", mt: 2, flexDirection: "column" }}
        >
          {appState.profilePicture != null && appState.profilePicture != "" ? (
            <img
              src={appState.profilePicture
                .replace("/uc?", "/thumbnail?")
                .replace("&export=view", "")}
              alt="Profile"
              style={{
                // height: "100%",
                height: "80px",
                width: "100%",
                objectFit: "cover",
                maxHeight: "80px",
                maxWidth: "80px",
                borderRadius: "40px",
              }}
            />
          ) : (
            <Box
              sx={{
                height: "80px",
                width: "80px",
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PersonIcon sx={{ color: "black", height: 50, width: 50 }} />
            </Box>
          )}
          <Typography
            sx={{ color: "white", mt: 2, fontSize: 15, textAlign: "center" }}
          >
            {appState.fullName}
          </Typography>
        </ListItem>

        <List>
          {navigationItems
            .filter((el) => el.show)
            .map((el, index) => (
              <ListItem onClick={handleDrawerToggle} disablePadding key={index}>
                <ListItemButton
                  sx={{ bgcolor: "secondary" }}
                  selected={pathname == el.path}
                  onClick={() => {
                    navigate(el.path);
                  }}
                >
                  <ListItemIcon sx={{ color: "white", marginRight: 1 }}>
                    {el.icon}
                  </ListItemIcon>
                  <ListItemText primary={el.name} sx={{ color: "white" }} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ bgcolor: "secondary" }}
            onClick={() => {
              setOpenDialog(true);
              setDialogTitle("Confirmar");
              setDialogDesc("¿Estás seguro de que deseas salir?");
              setDialogButton([
                <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>,
                <Button
                  onClick={() => {
                    setOpenDialog(false);
                    dispatch(logout());
                    setBodyColor("#00166B");
                    document.body.classList.add("body-custom-bg");
                  }}
                >
                  Ok
                </Button>,
              ]);
            }}
          >
            <ListItemIcon sx={{ color: "white", marginRight: 1 }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Salir" sx={{ color: "white" }} />
          </ListItemButton>
        </ListItem>
      </Drawer>
      {/* Desktop Drawer */}
      <Drawer
        open
        variant="permanent"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "#00166B !important",
          },
        }}
      >
        <ListItem>
          <img
            alt="Logo"
            src={logo}
            style={{ maxHeight: "40px", width: "100%", objectFit: "contain" }}
          />
        </ListItem>
        <ListItem
          sx={{ flexDirection: "column", justifyContent: "center", mt: 2 }}
        >
          {appState.profilePicture != null && appState.profilePicture != "" ? (
            <img
              alt="Profile"
              src={appState.profilePicture
                .replace("/uc?", "/thumbnail?")
                .replace("&export=view", "")}
              style={{
                //height: "100%",
                height: "80px",
                width: "100%",
                objectFit: "cover",
                maxHeight: "80px",
                maxWidth: "80px",
                borderRadius: "40px",
              }}
            />
          ) : (
            <Box
              sx={{
                height: "80px",
                width: "80px",
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PersonIcon sx={{ color: "black", height: 50, width: 50 }} />
            </Box>
          )}
          <Typography
            sx={{ color: "white", mt: 2, fontSize: 15, textAlign: "center" }}
          >
            {appState.fullName}
          </Typography>
        </ListItem>

        {drawerList}
        <ListItem disablePadding>
          <ListItemButton
            sx={{ bgcolor: "secondary" }}
            onClick={() => {
              setOpenDialog(true);
              setDialogTitle("Confirmar");
              setDialogDesc("¿Estas seguro de que deseas salir?");
              setDialogButton([
                <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>,
                <Button
                  onClick={() => {
                    setOpenDialog(false);
                    dispatch(logout());
                    setBodyColor("#00166B");
                    document.body.classList.add("body-custom-bg");
                  }}
                >
                  Ok
                </Button>,
              ]);
            }}
          >
            <ListItemIcon sx={{ color: "white", marginRight: 1 }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Salir" sx={{ color: "white" }} />
          </ListItemButton>
        </ListItem>
      </Drawer>

      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
    </Box>
  );
}

export default SideNavigation;
