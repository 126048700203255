export enum UtilsEndPoints
{
    GenericItems = "/api/utils/genericitems",
    GetSubsidiaries = "/api/utils/subsidiaries",
    GetInterviewDate = "/api/utils/interviewdate",
    GetHoursForDate = "/api/utils/hoursfordate",
    GetCountries = "/api/utils/countries",
    GetProvince = "/api/utils/provinces",
    GetCities = "/api/utils/cities",
    GetTShirtSize = "/api/utils/tshirtsize",
    GetCivilStatus = "/api/utils/civilstatus",
    GetProgramTypes = "/api/utils/programtypes",
    GetFamilyRelationships = "/api/utils/familyrelationships",
    GetUniversities = "/api/utils/universities",
    GetUniversityCareers = "/api/utils/universitycareers",
    GetUniversityPeriodTypes = "/api/utils/universityperiodtypes",
    GetVisaTypes = "/api/utils/visatypes",
    GetMigratoryStates = "/api/utils/migratorystate",
    GetFoundAboutUs = "/api/utils/foundaboutus",
    GetLanguageLevels = "/api/utils/languagelevels",
    GetBankAccounts = "/api/utils/bankaccounts",

    GetSponsors = "/api/utils/sponsors",
    GetWorkCompanies = "/api/utils/workcompanies",
    GetWorkPositions = "/api/utils/workpositions",
    GetWelcomeCalls = "/api/utils/welcomecalls"

}

export enum AuthEndPoints
{
    Register = "/api/auth/register",
    ActivationCode = "/api/auth/activateaccount",
    Login = "/api/auth/login",
    ResendConfirmationEmail  = "/api/auth/resendconfirmation",
    LoadUser = "/api/auth/getusersession",
    ForgotPassword = "/api/auth/forgotpassword",
    ResetPassword = "/api/auth/resetpassword"
}

export enum CandidateEndPoints
{
    GetGeneralInformation = "/api/candidate/generalinformation",
    SaveGeneralInformation = "/api/candidate/savegeneralinformation",
    GetPassportInformation = "/api/candidate/passportinformation",
    SavePassportInformation = "/api/candidate/savepassportinformation",
    SaveCandidateVisa = "/api/candidate/savecandidatevisa",
    GetCandidateVisas = "/api/candidate/candidatevisas",
    DeleteCandidateVisa = "/api/candidate/deletecandidateVisa",
    GetCandidateCountryVisit = "/api/candidate/candidatecountryvisits",
    DeleteCandidateCountryVisit = "/api/candidate/deletecandidatecountryvisit",
    SaveCandidateCountryVisit = "/api/candidate/savecandidatecountryvisit",
    GetCandidateParticipatePrograms = "/api/candidate/candidateparticipateprograms",
    SaveCandidateParticipateProgram = "/api/candidate/savecandidateparticipateprogram",
    DeleteCandidateParticipateProgram = "/api/candidate/deletecandidateparticipateprogram",
    GetCandidateWorks = "/api/candidate/candidateWorks",
    SaveCandidateWork = "/api/candidate/savecandidatework",
    DeleteCandidateWork = "/api/candidate/deletecandidatework",
    GetCurrentCandidataWork = "/api/candidate/candidatecurrentwork",
    GetCandidateEducation = "/api/candidate/candidateeducation",
    SaveCandidateEducation = "/api/candidate/savecandidateeducation",
    GetCandidateEducationInterruptions  = "/api/candidate/educationinterruptions",
    SaveCandidateEducationInterruption = "/api/candidate/saveeducationinterruption",
    DeleteCandidateEducationInterruption = "/api/candidate/deleteeducationinterruption",
    GetFamilyInformation = "/api/candidate/familyinformation",
    SaveCandidateRelatedFamily = "/api/candidate/savecandidaterelatedfamily",
    GetCandidateRelatedFamily = "/api/candidate/relatedfamily",
    GetCandidateOtherFamily = "/api/candidate/otherfamilies",
    SaveCandidateFamilyInfo = "/api/candidate/savecandidatefamilyinfo",
    SaveCandidateOtherCloseFamily = "/api/candidate/savecandidateotherfamily",
    DeleteCandidateRelated = "/api/candidate/deletecandidaterelated",
    GetCandidateOtherCloseFamilyInformation = "/api/candidate/otherfamilyinformation",
    SaveOtherFamilyInformation = "/api/candidate/saveotherfamilyinformation",
    GetCandidateReferences = "/api/candidate/candidatereferences",
    SaveCandidateReference = "/api/candidate/savecandidatereference",
    GetCandidateLanguageList = "/api/candidate/candidatelanguageslist",
    GetCandidateLanguages = "/api/candidate/candidatelanguages",
    SaveCandidateLanguage = "/api/candidate/savecandidatelanguage",
    DeleteCandidateLanguage = "/api/candidate/deletecandidatelanguage",
    SaveCandidateOtherInformation = "/api/candidate/savecandidateotherinformation",
    GetCandidateOtherInformation = "/api/candidate/candidateotherinfo",
    SaveCandidateInitialInterview = "/api/candidate/savecandidateinterview",
    GetCandidateDocuments = "/api/candidate/candidatedocuments",
    UploadCandidateDocument = "/api/candidate/uploadcandidatedocument",
    UpdateCandidateDocument = "/api/candidate/updatecandidatedocument",
    SaveCandidateVoucher = "/api/candidate/savecandidatevoucher",
    GetCandidateVouchers = "/api/candidate/candidatevouchers",
    GetCandidateGuarantors = "/api/candidate/candidateguarantors",
    SaveCnadidateGuarantorFile = "/api/candidate/savecandidateguarantorfile",
    SaveCandidateGuarantor = "/api/candidate/savecandidateguarantor",
    GetCandidateGuarantorStatus = "/api/candidate/candidateguarantorstatus",
    GetAccountCandidates = "/api/candidate/accountcandidates",
    SaveCandidateTrip = "/api/candidate/savecandidatetrip",
    GetCandidateTrip = "/api/candidate/candidatetrip",
    GetCandidate = "/api/candidate",
    GenerateCandidateTripQRCode = "/api/candidate/generatetripqrcode",
    GetCandidateConsularAppointment = "/api/candidate/candidateconsularappointment",
    GetCandidateMissingItems = "/api/candidate/candidatemissingitems",
    GeneratePassportQRCode = "/api/candidate/generatepassportqrcode",
    GetCandidatePassportQRCodeInfo = "/api/candidate/candidatepassportinfo",
    GetCandidateJobsAvailable = "/api/candidate/candidatejobsavailable",
    ApplyForJob = "/api/candidate/applyforjob",
    GetCandidateJob = "/api/candidate/candidatejob",
    GetCandidateWorkRefuseds = "/api/candidate/candidateworkrefuseds",
    GetCandidatePreDepartureDocuments = "/api/candidate/predeparturedocuments",
    SendAndGenerateDocumentCode = "/api/candidate/sendandgeneratecode",
    SignCandidateDocument  = "/api/candidate/signcandidatedocument",
    SaveDS160 = "/api/candidate/saveds160",
    GetDS160 = "/api/candidate/ds160",
    CheckIsCandidateRepeating = "/api/candidate/isrepeating",
    SaveCandidateRepeatingOtherInformation = "/api/candidate/saverepeatingcandidateinformation",
    GetCandidateRepeatingOtherInformacion = "/api/candidate/candidaterepeatingotherinfo",
    GetCandidatePaymentDone = "/api/candidate/paymentdone",
    GetCandidatePaymentAccounts = "/api/candidate/paymentaccounts",
    GetPaymentReference = "/api/candidate/paymentreference",
    GetPendingPayments = "/api/candidate/pendingpayments",
    ReApplySeason = "/api/candidate/reapplyseason",
    GetCandidateResources = "/api/candidate/candidateresources",
    AddEditWelcomeInterview= "/api/candidate/addeditwelcomeinterview",
    GetCandidateEventTicket= "/api/candidate/candidateeventticket",
    AcceptDenyEventTicket="/api/candidate/denyaccepteventticket",
    GetCandidateEventTicketsAccepted= "/api/candidate/candidateeventticketsaccepted",
    CannotReApply = "/api/candidate/cannotreapply",
    GetReferido = "/api/candidate/referidos",
    AddEditReferido = "/api/candidate/addeditreferido",
    DeleteReferido = "/api/candidate/deletereferido"
}