import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import candidateService from "../services/CandidateService";
import CandidateConsularAppointment from "../models/CandidateConsularAppointment";
import { StyledTableCell, StyledTableRow } from "../theme/GlobalTheme";
import { GetDateDDMMYYYYY } from "../Utils";

function ConsularAppointmentScreen() {
  const [consularAppointments, setConsularAppointments] = useState<
    CandidateConsularAppointment[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  async function initialData() {
    const consular = await candidateService.getCandidateConsularAppointment();
    setConsularAppointments(consular);
    setIsLoading(false);
  }

  useEffect(() => {
    initialData();
  }, []);
  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Citas Consulares
        </Typography>
      </Box>
      {/* <Typography sx={{ mb: 3 }} variant="h3">
        Citas Consulares
      </Typography> */}

      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Evento</StyledTableCell>
                <StyledTableCell align="left">Fecha Cita</StyledTableCell>
                <StyledTableCell align="left">Resultado</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {consularAppointments.length == 0 && (
                <StyledTableRow>
                  <StyledTableCell sx={{ textAlign: "center" }} colSpan={3}>
                    No data disponible
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {consularAppointments.map((el, index) => (
                <StyledTableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <StyledTableCell align="left">{el.evento}</StyledTableCell>
                  <StyledTableCell align="left">
                    {el.fechaCita != null
                      ? new Date(el.fechaCita).toLocaleDateString("es-ES", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : ""}
                  </StyledTableCell>
                  <StyledTableCell align="left">{el.resultado}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
export default ConsularAppointmentScreen;
