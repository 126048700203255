import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { StyledTableCell, StyledTableRow } from "../theme/GlobalTheme";
import CloseIcon from "@mui/icons-material/Close";
import Paragraph from "../components/Paragraph";
import AppTextField from "../components/AppTextField";
import Referido from "../models/Referido";
import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import ErrorText from "../components/ErrorText";
import CandidateService from "../services/CandidateService";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationDialog from "../components/ConfirmationDialog";
import LoadingModal from "../components/LoadingModal";
import { phoneFormatter } from "../Utils";

const validationSchema = Yup.object().shape({
  referidoNombreCompleto: Yup.string().required("Requerido"),
  referidoEmail: Yup.string()
    .email("Email no es valido")
    .required("Email requerido"),
  referidoCelular: Yup.string().required("Requerido"),
  referidoId: Yup.number().optional(),
});

export default function ReferidoScreen() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [referidos, setReferidos] = useState<Referido[]>([]);
  const [addEditReferido, setAddEditReferido] = useState<Referido>({
    referidoId: 0,
    referidoNombreCompleto: "",
    referidoCelular: "",
    referidoEmail: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  async function initialLoad() {
    const refs = await CandidateService.getReferidos();
    setReferidos(refs);
    setIsLoading(false);
  }

  async function submit(values: Referido) {
    try {
      setIsSaving(true);

      await CandidateService.addEditReferido({
        ...values,
        referidoId: addEditReferido.referidoId,
      });
      setIsSaving(false);
      setIsLoading(true);
      setIsOpen(false);
      initialLoad();
    } catch (error) {
      setIsLoading(false);
      setIsSaving(false);
      setDialogTitle("Lo Sentimos");
      setDialogDesc("Ha ocurrido un error. Por favor intente de nuevo.");
      setOpenDialog(true);
      setDialogButton(
        <Button
          onClick={() => {
            setOpenDialog(false);
          }}
        >
          Ok
        </Button>
      );
    }
  }

  async function deleteReferido(referidoId: number) {
    setDialogTitle("Confirmar");
    setDialogDesc("Estas seguro que deseas eliminar este referido?");
    setOpenDialog(true);
    setDialogButton([
      <Button
        color="error"
        variant="contained"
        onClick={async () => {
          setOpenDialog(false);
          try {
            setIsSaving(true);
            await CandidateService.deleteReferido(referidoId);
            setIsSaving(false);
            setIsLoading(true);
            initialLoad();
          } catch (error) {
            setIsLoading(false);
            setIsSaving(false);
            setDialogTitle("Lo Sentimos");
            setDialogDesc("Ha ocurrido un error. Por favor intente de nuevo.");
            setOpenDialog(true);
            setDialogButton(
              <Button
                onClick={() => {
                  setOpenDialog(false);
                }}
              >
                Ok
              </Button>
            );
          }
        }}
      >
        Eliminar
      </Button>,
      <Button
        onClick={() => {
          setOpenDialog(false);
        }}
      >
        Cancelar
      </Button>,
    ]);
  }

  useEffect(() => {
    initialLoad();
  }, []);

  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Referidos
        </Typography>
      </Box>
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <Button
            sx={{ mb: 2 }}
            variant="contained"
            color="secondary"
            onClick={() => {
              setAddEditReferido({
                referidoId: 0,
                referidoNombreCompleto: "",
                referidoCelular: "",
                referidoEmail: "",
              });
              setIsOpen(true);
            }}
          >
            Agregar Referido
          </Button>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">
                    Nombre Completo
                  </StyledTableCell>
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left">Celular</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {referidos.length == 0 && (
                  <StyledTableRow>
                    <StyledTableCell align="center" colSpan={4}>
                      No data disponible
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {referidos.map((el) => (
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {el.referidoNombreCompleto}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {el.referidoEmail}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {el.referidoCelular}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box>
                        <IconButton
                          sx={{ mr: 1 }}
                          color="error"
                          onClick={async () => {
                            deleteReferido(el.referidoId);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          color="success"
                          onClick={() => {
                            setAddEditReferido(el);
                            setIsOpen(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
      {/* Add Edit Referido */}
      <Dialog fullWidth={true} maxWidth="sm" open={isOpen}>
        <DialogTitle>
          Agregar/Editar Referido
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={addEditReferido}
            onSubmit={submit}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
          >
            {(props: FormikProps<Referido>) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                isSubmitting,
                setFieldValue,
                isValid,
              } = props;

              return (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <Paragraph title="Nombre Completo" required />

                      <AppTextField
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        error={
                          errors.referidoNombreCompleto &&
                          touched.referidoNombreCompleto
                            ? true
                            : false
                        }
                        name="referidoNombreCompleto"
                        value={values.referidoNombreCompleto}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.referidoNombreCompleto &&
                      touched.referidoNombreCompleto ? (
                        <ErrorText text={errors.referidoNombreCompleto} />
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <Paragraph title="Email" required />

                      <AppTextField
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        error={
                          errors.referidoEmail && touched.referidoEmail
                            ? true
                            : false
                        }
                        name="referidoEmail"
                        value={values.referidoEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.referidoEmail && touched.referidoEmail ? (
                        <ErrorText text={errors.referidoEmail} />
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <Paragraph title="Celular" required />

                      <AppTextField
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        error={
                          errors.referidoCelular && touched.referidoCelular
                            ? true
                            : false
                        }
                        name="referidoCelular"
                        value={values.referidoCelular}
                        //onChange={handleChange}
                        onBlur={handleBlur}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const phoneRegex =
                            /^(\d{0,3})-?(\d{0,3})-?(\d{0,4})$/;
                          const { value } = event.target;
                          if (phoneRegex.test(value)) {
                            const formattedPhone = phoneFormatter(value);
                            setFieldValue("referidoCelular  ", formattedPhone);
                          }
                        }}
                      />
                      {errors.referidoCelular && touched.referidoCelular ? (
                        <ErrorText text={errors.referidoCelular} />
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        disabled={isSubmitting}
                        fullWidth
                        type="submit"
                        color="secondary"
                        variant="contained"
                        sx={{ bgcolor: "secondary.main" }}
                      >
                        {isSubmitting ? <>Guardando...</> : "Guardar"}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
