import axios from "axios";
import { CandidateEndPoints } from "../Settings/EndPoints";
import GeneralInformation from "../models/GeneralInformation";
import PassportInformation from "../models/PassportInformation";
import CandidateVisa from "../models/CandidateVisa";
import CandidateCountryVisit from "../models/CandidateCountryVisit";
import CandidateParticipateProgram from "../models/CandidateParticipateProgram";
import CandidateWork from "../models/CandidateWork";
import CandidateEducation from "../models/CandidateEducation";
import { CandidateEducationInterruption } from "../models/CandidateEducationInterruption";
import CandidateFamilyInformation from "../models/CandidateFamilyInformation";
import CandidateCloseFamily from "../models/CandidateCloseFamily";
import CandidateOtherCloseFamily from "../models/CandidateOtherCloseFamily";
import { CandidateOtherCloseFamilyInformation } from "../models/CandidateOtherCloseFamilyInformation";
import CandidateReference from "../models/CandidateReference";
import CandidateLanguageList from "../models/CandidateLanguageList";
import CandidateLanguage from "../models/CandidateLanguage";
import CandidateOtherInformation from "../models/CandidateOtherInformation";
import CandidateInterviewUpdate from "../models/CandidateInterviewUpdate";
import CandidateDocument from "../models/CandidateDocument";
import CandidateVoucher from "../models/CandidateVoucher";
import { stringify } from "querystring";
import CandidateSponsor from "../models/CandidateGuarantor";
import { CandidateSponsorAdd } from "../models/CandidateSponsorAdd";
import CandidateGuarantor from "../models/CandidateGuarantor";
import CandidateGuarantorStatus from "../models/CandidateGuarantorStatus";
import { AccountCandidate } from "../models/AccountCandidate";
import { CandidateTrip } from "../models/CandidateTrip";
import Candidate from "../models/Candidate";
import CandidateTripGenerateQR from "../models/CandidateTripGenerateQR";
import CandidateConsularAppointment from "../models/CandidateConsularAppointment";
import CandidateMissingItem from "../models/CandidateMissingItem";
import CandidateQRPassportInfo from "../models/CandidatePassportQRInfo";
import { CandidateJobAvailable } from "../models/CandidateJobAvailable";
import CandidateJob from "../models/CandidateJob";
import CandidateWorkRefused from "../models/CandidateWorkRefused";
import CandidatePreDepartureDocument from "../models/CandidatePreDepartureDocument";
import SignCandidateDocument from "../models/SignCandidateDocument";
import CandidateRepeatingOtherInformation from "../models/CandidateRepeatingOtherInformacion";
import FormDefinition from "../models/FormDefinition";
import CandidatePaymentDone from "../models/CandidatePaymentDone";
import CandidatePaymentAccount from "../models/CandidatePaymentAccount";
import PaymentSelected from "../models/PaymentSelected";
import PaymentReference from "../models/PaymentReference";
import CandidatePendingPayment from "../models/CandidatePendingPayments";
import CandidateResource from "../models/CandidateResource";
import CandidateEventTicket from "../models/CandidateEventTicket";
import Referido from "../models/Referido";

class CandidateService
{
    async getGeneralInformation() : Promise<GeneralInformation>{

        var resp = await axios.get(CandidateEndPoints.GetGeneralInformation);
        var generalinformation: GeneralInformation = resp.data;
        return generalinformation;
    }
     async saveGeneralInformation(generalinformation: GeneralInformation){
        var resp = await axios.post(CandidateEndPoints.SaveGeneralInformation,generalinformation);
    }

    async getPassportInformation() : Promise<PassportInformation>{

        var resp = await axios.get(CandidateEndPoints.GetPassportInformation);
        var passportInformation: PassportInformation = resp.data;
        return passportInformation;
    }

    async savePassportInformation(passportInformation: PassportInformation){
        var resp = await axios.post(CandidateEndPoints.SavePassportInformation,passportInformation);
    }
    async saveCandidateVisa(candidateVisa: CandidateVisa): Promise<CandidateVisa>
    {
        var resp = await axios.post(CandidateEndPoints.SaveCandidateVisa, candidateVisa);
        var visa: CandidateVisa = resp.data;
        return visa;
    }

    async getCandidateVisas() : Promise<CandidateVisa[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateVisas);
        var visas: CandidateVisa[] = resp.data;
        return visas;
    }
    async deleteCandidateVisa(candidateVisaId: number)
    {
        var resp = await axios.delete(CandidateEndPoints.DeleteCandidateVisa + `?candidateVisaId=${candidateVisaId}`);
    }

    async getCandidateCountryVisits() : Promise<CandidateCountryVisit[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateCountryVisit);
        var visas: CandidateCountryVisit[] = resp.data;
        return visas;
    }

    async saveCandidateCountryVisit(visit: CandidateCountryVisit): Promise<CandidateCountryVisit>
    {
        var resp = await axios.post(CandidateEndPoints.SaveCandidateCountryVisit, visit);
        var visa: CandidateCountryVisit = resp.data;
        return visa;
    }

    async deleteCandidateCountryVisit(candidateCountryVisitId: number)
    {
        await axios.delete(CandidateEndPoints.DeleteCandidateCountryVisit + `?candidateCountryVisitId=${candidateCountryVisitId}`);
    }

    async getCandidateParticipatePrograms() : Promise<CandidateParticipateProgram[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateParticipatePrograms);
        var programs: CandidateParticipateProgram[] = resp.data;
        return programs;
    }

    async saveCandidateParticipateProgram(program: CandidateParticipateProgram): Promise<CandidateParticipateProgram>
    {
        var resp = await axios.post(CandidateEndPoints.SaveCandidateParticipateProgram, program);
        var pro: CandidateParticipateProgram = resp.data;
        return pro;
    }

    async deleteCandidateParticipateProgram(candidateParticipateProgramId: number)
    {
        await axios.delete(CandidateEndPoints.DeleteCandidateParticipateProgram + `?candidateParticipateProgramId=${candidateParticipateProgramId}`);
    }
    async getCandidateWorks() : Promise<CandidateWork[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateWorks);
        var works: CandidateWork[] = resp.data;
        return works;
    }
    async getCandidateCurrentWork() : Promise<CandidateWork>{

        var resp = await axios.get(CandidateEndPoints.GetCurrentCandidataWork);
        var work: CandidateWork = resp.data;
        return work;
    }

    async saveCandidateWork(candidateWork: CandidateWork): Promise<CandidateWork>
    {
        var resp = await axios.post(CandidateEndPoints.SaveCandidateWork, candidateWork);
        var work: CandidateWork = resp.data;
        return work;
    }

    async deleteCandidateWork(candidateWorkId: number)
    {
        await axios.delete(CandidateEndPoints.DeleteCandidateWork + `?candidateWorkId=${candidateWorkId}`);
    }

    async saveCandidataEducation(education: CandidateEducation): Promise<CandidateEducation>
    {
        var resp = await axios.post(CandidateEndPoints.SaveCandidateEducation, education);
        var edu: CandidateEducation = resp.data;
        return edu;
    }

    async getCandidateEducation() : Promise<CandidateEducation>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateEducation);
        var work: CandidateEducation = resp.data;
        return work;
    }

    async getCandidateEducationInterruptions() : Promise<CandidateEducationInterruption[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateEducationInterruptions);
        var work: CandidateEducationInterruption[] = resp.data;
        return work;
    }

    async saveCandidateEducationInterruption(interruption: CandidateEducationInterruption): Promise<CandidateEducationInterruption>
    {
        var resp = await axios.post(CandidateEndPoints.SaveCandidateEducationInterruption, interruption);
        var edu: CandidateEducationInterruption = resp.data;
        return edu;
    }
    async deleteCandidateEducationInterruption(interruptionId: number)
    {
        await axios.delete(CandidateEndPoints.DeleteCandidateEducationInterruption + `?interruptionId=${interruptionId}`);
    }

    async getFamilyInformation(): Promise<CandidateFamilyInformation>{
        var resp = await axios.get(CandidateEndPoints.GetFamilyInformation);
        var fa: CandidateFamilyInformation = resp.data;
        return fa;
    }

    async getCandidateRelatedFamily(type: string): Promise<CandidateCloseFamily | null>{
        var resp = await axios.get(CandidateEndPoints.GetCandidateRelatedFamily + `?relatedType=${type}`);
        var fa: CandidateCloseFamily = resp.data;
        if(resp.data == ""){
            return null
        }
        return fa ;
    }

    async getCandidateOtherFamilies(relatedType: string) : Promise<CandidateOtherCloseFamily[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateOtherFamily + `?relatedType=${relatedType}`);
        var work: CandidateOtherCloseFamily[] = resp.data;
        return work;
    }

    async getCandidateReferences(relatedType: string) : Promise<CandidateReference[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateReferences + `?relatedType=${relatedType}`);
        var work: CandidateReference[] = resp.data;
        return work;
    }


    async getCandidateOtherFamilyInformation() : Promise<CandidateOtherCloseFamilyInformation>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateOtherCloseFamilyInformation);
        var info: CandidateOtherCloseFamilyInformation = resp.data;
        return info;
    }

    async saveCandidateFamilyRelated(family: CandidateCloseFamily)
    {
        await axios.post(CandidateEndPoints.SaveCandidateRelatedFamily, family);
    }
    async saveCandidateFamilyInfo(info: CandidateFamilyInformation)
    {
        await axios.post(CandidateEndPoints.SaveCandidateFamilyInfo, info);
    }
    async saveCandidateOtherCloseFamily(info: CandidateOtherCloseFamily)
    {
        await axios.post(CandidateEndPoints.SaveCandidateOtherCloseFamily, info);
    }
    async saveCandidateCloseOtherFamilyInformation(info: CandidateOtherCloseFamilyInformation)
    {
        await axios.post(CandidateEndPoints.SaveOtherFamilyInformation, info);
    }
    async saveCandidateReference(reference: CandidateReference)
    {
        await axios.post(CandidateEndPoints.SaveCandidateReference, reference);
    }

    async deleteCandidateRelated(candidateRelatedId: number)
    {
        await axios.delete(CandidateEndPoints.DeleteCandidateRelated + `?candidateRelatedId=${candidateRelatedId}`);
    }

    async getCandidateLanguageList() : Promise<CandidateLanguageList[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateLanguageList);
        var work: CandidateLanguageList[] = resp.data;
        return work;
    }

    async getCandidateLanguages() : Promise<CandidateLanguage[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateLanguages);
        var languages: CandidateLanguage[] = resp.data;
        return languages;
    }

    async saveCandidateLanguage(candidateLanguage: CandidateLanguage)
    {
        await axios.post(CandidateEndPoints.SaveCandidateLanguage, candidateLanguage);
    }

    async deleteCandidateLanguage(candidateLanguageId: number)
    {
        await axios.delete(CandidateEndPoints.DeleteCandidateLanguage + `?candidateLanguageId=${candidateLanguageId}`);
    }

    async saveCandidateOtherInformation(otherInfo: CandidateOtherInformation)
    {
       await axios.post(CandidateEndPoints.SaveCandidateOtherInformation, otherInfo);
    }

    async getCandidateOtherInformation() : Promise<CandidateOtherInformation>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateOtherInformation);
        var info: CandidateOtherInformation = resp.data;
        return info;
    }

    async saveCandidateInterview(interviewUpdate: CandidateInterviewUpdate)
    {
        await axios.post(CandidateEndPoints.SaveCandidateInitialInterview, interviewUpdate);
    }

    async getCandidateDocuments() : Promise<CandidateDocument[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateDocuments);
        var info: CandidateDocument[] = resp.data;
        return info;
    }

    async uploadCandidateDocument(documentId: number, file: File)
    {
        var formData = new FormData();
        formData.append("file", file);

        await axios.post(CandidateEndPoints.UploadCandidateDocument + `?candidateDocumentId=${documentId}`,formData);
    }
    async updateCandidateDocument(documentId: number)
    {
        await axios.post(CandidateEndPoints.UpdateCandidateDocument + `?candidateDocumentId=${documentId}`);
    }

    async saveCandidateVoucher(voucher: CandidateVoucher, file: File)
    {
        var formData = new FormData();
        formData.append("file", file);
        //formData.append("voucher", JSON.stringify(voucher))
        formData.append("bankAccountId", voucher.bankAccountId.toString())
        formData.append("transactionTypeCode", voucher.transactionTypeCode)
        formData.append("transactionDateTime", voucher.transactionDateTime)
        formData.append("exchangeRateId", voucher?.exchangeRateId.toString())
        formData.append("transactionAmount", voucher.transactionAmount.toString())
        formData.append("transactionCashier", voucher.transactionCashier)
        formData.append("transactionOffice", voucher.transactionOffice )
        formData.append("transactionTime", voucher.transactionTime ?? "");
        formData.append("comment", voucher.comment);
        formData.append("transactionNumber", voucher.transactionNumber );

        await axios.post(CandidateEndPoints.SaveCandidateVoucher,formData, {
            headers:{
            'Content-Type': 'multipart/form-data'
        }});
    }

    async getCandidateVouchers() : Promise<CandidateVoucher[]>{
        var resp = await axios.get(CandidateEndPoints.GetCandidateVouchers);
        var info: CandidateVoucher[] = resp.data;
        return info;
    }
    async getCandidateGuarantors() : Promise<CandidateGuarantor[]>{
        var resp = await axios.get(CandidateEndPoints.GetCandidateGuarantors);
        var info: CandidateSponsor[] = resp.data;
        return info;
    }

    async saveCandidateGuarantorFile(documentTypeId: number, sponsorId: number , file: File)
    {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("sponsorId", sponsorId.toString())
        formData.append("documentTypeId", documentTypeId.toString())


        await axios.post(CandidateEndPoints.SaveCnadidateGuarantorFile,formData, {
            headers:{
            'Content-Type': 'multipart/form-data'
        }});
    }

    async saveCandidateGuarantor(sponsor: CandidateSponsorAdd , file: File)
    {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("guarantorID", sponsor.guarantorID)
        formData.append("guarantorName", sponsor.guarantorName)
        formData.append("guarantorLastName", sponsor.guarantorLastName)
        formData.append("guarantorOccupation", sponsor.guarantorOccupation)
        formData.append("civilStatus", sponsor.civilStatus)
        formData.append("genero", sponsor.genero)
        formData.append("guarantorAddress", sponsor.guarantorAddress)
        formData.append("sector", sponsor.sector)
        formData.append("provinceId", sponsor.provinceId.toString());
        formData.append("cityId", sponsor.cityId.toString());

        await axios.post(CandidateEndPoints.SaveCandidateGuarantor,formData, {
            headers:{
            'Content-Type': 'multipart/form-data'
        }});
    }

    async getCandidateGuarantorStatus() : Promise<CandidateGuarantorStatus>{
        var resp = await axios.get(CandidateEndPoints.GetCandidateGuarantorStatus)
        var ret: CandidateGuarantorStatus = resp.data;
        return ret;
    }

    async getAccountCandidates(): Promise<AccountCandidate[]>{
        var resp = await axios.get(CandidateEndPoints.GetAccountCandidates)
        var ret: AccountCandidate[] = resp.data;
        return ret;
    }


    async saveCandidateTrip(candidateTrip: CandidateTrip, file: File | null)
    {
        var formData = new FormData();
        if(file != null){
            formData.append("file", file);
        }

        formData.append("isFlightTo", candidateTrip.isFlightTo.toString())
        formData.append("flightDate", candidateTrip.flightDate.toString())
        formData.append("flightDateHour", candidateTrip.flightDateHour.toString())
        formData.append("flightDateAirline", candidateTrip.flightDateAirline.toString())
        formData.append("flightDateReservationCode", candidateTrip.flightDateReservationCode.toString())
        formData.append("flightDateAirPort", candidateTrip.flightDateAirPort.toString())
        formData.append("flightDateAirPortArrived", candidateTrip.flightDateAirPortArrived.toString())
        formData.append("flightDateMinute", candidateTrip.flightDateMinute.toString())
        formData.append("travelInfoID", candidateTrip.travelInfoID.toString())

        await axios.post(CandidateEndPoints.SaveCandidateTrip,formData, {
            headers:{
            'Content-Type': 'multipart/form-data'
        }});
    }

    async getCandidateTrip(isFlightTo: boolean): Promise<CandidateTrip | null>{
        var resp = await axios.get(CandidateEndPoints.GetCandidateTrip + `?isFlightTo=${isFlightTo}`)
        var ret: CandidateTrip | null = resp.data;

        if(resp.data == ""){
            ret = null
        }

        return ret;
    }

    async getCandidate(isTrip: boolean = false): Promise<Candidate>{
        var resp = await axios.get(CandidateEndPoints.GetCandidate + `?isTrip=${isTrip}`)
        var ret: Candidate  = resp.data
        return ret;
    }
    async generateCandidateTripQRCode(gen: CandidateTripGenerateQR)
    {
        await axios.post(CandidateEndPoints.GenerateCandidateTripQRCode, gen);
    }

    async getCandidateConsularAppointment(): Promise<CandidateConsularAppointment[]>{
        var resp = await axios.get(CandidateEndPoints.GetCandidateConsularAppointment)
        var ret: CandidateConsularAppointment[]  = resp.data
        return ret;
    }
    async getCandidateMissingItem(): Promise<CandidateMissingItem[]>{
        var resp = await axios.get(CandidateEndPoints.GetCandidateMissingItems)
        var ret: CandidateMissingItem[]  = resp.data
        return ret;
    }

    async generatePassportQRCode()
    {
        await axios.post(CandidateEndPoints.GeneratePassportQRCode);
    }

    async getPassportQRCodeInfo(): Promise<CandidateQRPassportInfo>{
        var resp = await axios.get(CandidateEndPoints.GetCandidatePassportQRCodeInfo)
        var ret: CandidateQRPassportInfo  = resp.data
        return ret;
    }

    async getCandidateJobsAvailable(): Promise<CandidateJobAvailable[]>{
        var resp = await axios.get(CandidateEndPoints.GetCandidateJobsAvailable)
        var ret: CandidateJobAvailable[]  = resp.data

        return ret;
    }

    async applyForJob(workPlaceItemId: number)
    {
        await axios.post(CandidateEndPoints.ApplyForJob + `?workPlaceItemId=${workPlaceItemId}`);
    }

    async getCandidateJob(): Promise<CandidateJob | null>{
        var resp = await axios.get(CandidateEndPoints.GetCandidateJob)
        var ret: CandidateJob | null = resp.data

        if(resp.data == ""){
           ret =  null
        }

        return ret;
    }

    async getCandidateWorkRefuseds() : Promise<CandidateWorkRefused[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateWorkRefuseds);
        var info: CandidateWorkRefused[] = resp.data;
        return info;
    }
    async getCandidatePreDepartureDocuments() : Promise<CandidatePreDepartureDocument[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidatePreDepartureDocuments);
        var info: CandidatePreDepartureDocument[] = resp.data;
        return info;
    }

    async sendAndGenerateDocumentCode(candidateDocumentId: number)
    {
        await axios.post(CandidateEndPoints.SendAndGenerateDocumentCode + `?candidateDocumentId=${candidateDocumentId}`);
    }

    async signCandidateDocument(signDocument: SignCandidateDocument)
    {
        await axios.post(CandidateEndPoints.SignCandidateDocument , signDocument);
    }
    async saveDS160(obj: string)
    {
        await axios.post(CandidateEndPoints.SaveDS160 + "?obj=" + obj );
    }
    async getDS160() : Promise<FormDefinition[]>{

        var resp = await axios.get(CandidateEndPoints.GetDS160);
        var info: FormDefinition[] = resp.data;
        return info;
    }

    async getCandidateRepeatingOtherInfo() : Promise<CandidateRepeatingOtherInformation>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateRepeatingOtherInformacion);
        var info: CandidateRepeatingOtherInformation = resp.data;
        return info;
    }
    async saveCandidateRepeatingOtherInformation(otherInfo: CandidateRepeatingOtherInformation, horario: File | null, foto: File | null )
    {
        const formData = new FormData();

        formData.append('repeatingSponsor', otherInfo.repeatingSponsor || '');
        formData.append('repeatingJobDescription', otherInfo.repeatingJobDescription || '');
        formData.append('repeatingPosition', otherInfo.repeatingPosition || '');
        formData.append('repeatingSameJob', otherInfo.repeatingSameJob?.toString() || '');
        formData.append('repeatingSameJobJustification', otherInfo.repeatingSameJobJustification || '');
        formData.append('repeatingSamePosition', otherInfo.repeatingSamePosition || '');
        formData.append('repeatingSamePositionJustificacion', otherInfo.repeatingSamePositionJustificacion || '');
        formData.append('repeatingGarantee', otherInfo.repeatingGarantee?.toString() || '');
        formData.append('repeatingParticipationCount', otherInfo.repeatingParticipationCount?.toString() || '');
        formData.append('repeating12CreditDecember', otherInfo.repeating12CreditDecember?.toString() || '');
        formData.append('repeating12CreditDecemberJustification', otherInfo.repeating12CreditDecemberJustification || '');
        formData.append('repeating12CreditJanuary', otherInfo.repeating12CreditJanuary?.toString() || '');
        formData.append('repeating12CreditJanuaryJustification', otherInfo.repeating12CreditJanuaryJustification || '');
        formData.append('repeatingMedica', otherInfo.repeatingMedica || '');

        if(horario != null){
            formData.append('horario', horario);
        }
        if(foto != null){
            formData.append('foto', foto);
        }

        await axios.post(CandidateEndPoints.SaveCandidateRepeatingOtherInformation , formData);

    }

    async getCandidatePaymentDone() : Promise<CandidatePaymentDone[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidatePaymentDone);
        var info: CandidatePaymentDone[] = resp.data;
        return info;
    }
    async getCandidatePaymentAccounts() : Promise<CandidatePaymentAccount[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidatePaymentAccounts);
        var info: CandidatePaymentAccount[] = resp.data;
        var ret: CandidatePaymentAccount[] = info.map((el) => {
            el.amountToPay = el.pendingAmount;
            el.isChecked = false;
            return el
        })
        return ret;
    }

    async getPaymentReference(obj: PaymentSelected): Promise<PaymentReference>
    {
        const resp = await axios.post(CandidateEndPoints.GetPaymentReference, obj);
        const ret: PaymentReference = resp.data;
        return ret;
    }

    async getPendingPayments() : Promise<CandidatePendingPayment[]>{

        var resp = await axios.get(CandidateEndPoints.GetPendingPayments);
        var info: CandidatePendingPayment[] = resp.data;
        return info;
    }

    async reApplySeason(): Promise<String>
    {
        var resp = await axios.post(CandidateEndPoints.ReApplySeason);
        return resp.data;
    }
    async getCandidateResources() : Promise<CandidateResource[]>{

        var resp = await axios.get(CandidateEndPoints.GetCandidateResources);
        var info: CandidateResource[] = resp.data;
        return info;
    }

    async addEditWelcomeInterview(id: string)
    {
        await axios.post(CandidateEndPoints.AddEditWelcomeInterview + `?id=${id}`);
    }

    async getCandidateEventTickets(): Promise<CandidateEventTicket[]>{
        var resp = await axios.get(CandidateEndPoints.GetCandidateEventTicket);
        var info: CandidateEventTicket[] = resp.data;
        return info;
    }

    async acceptDenyAventTicket(eventTicketId: number, quantity: number){
        await axios.post(CandidateEndPoints.AcceptDenyEventTicket + `?eventTicketId=${eventTicketId}&quantity=${quantity}`)
    }

    async getCandidateEventTicketsAccepted(): Promise<CandidateEventTicket[]>{
        var resp = await axios.get(CandidateEndPoints.GetCandidateEventTicketsAccepted);
        var info: CandidateEventTicket[] = resp.data;
        return info;
    }

    async cannotReApply(): Promise<boolean>{
        var resp = await axios.get(CandidateEndPoints.CannotReApply);
        var ret: boolean = resp.data;
        return ret;
    }
    async getReferidos(): Promise<Referido[]>{
        var resp = await axios.get(CandidateEndPoints.GetReferido);
        var ret: Referido[] = resp.data;
        return ret;
    }
    async addEditReferido(referido: Referido){

        await axios.post(CandidateEndPoints.AddEditReferido, {
            name: referido.referidoNombreCompleto.toUpperCase(),
            email: referido.referidoEmail.toUpperCase(),
            phone:referido.referidoCelular.toUpperCase(),
            referidoId: referido.referidoId
        })
    }
    async deleteReferido(referidoId: number){
        await axios.delete(CandidateEndPoints.DeleteReferido + `?referidoId=${referidoId}`);
    }
}


export default new CandidateService();